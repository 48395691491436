.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.foglalas_container{
    display: flex;
    justify-content: center; /* Vízszintes középre helyezés */
    align-items: center; /* Függőleges középre helyezés */
    width: 100%;
 /* A teljes magasság, hogy a középre helyezés látható legyen */
}

.vendegkezelo-item{
  border: #282c34 solid 0.2px;
  display: flex;
  gap: 25px;
  max-width: 70%;
  margin-left: 30px;
}