.front-containerr {
    margin-top: 35vh;
    display: grid;
    width: 100%;
    grid-template-columns: 58% 42%;
    background-color: #f5f9f5;
    margin-bottom: 25vh;
}

.front-szovegg {
    width: 80%;
    padding-left: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.front-szovegg h1 {
    font-size: 3em;
    text-align: left;
}

.front-szovegg p {
    font-size: 1.2em;
    color: #2d843d;
}

.p-container {
    padding-right: 20px;
    border-left: 2.4px solid #2d843d;
    padding-left: 15px;
}

.front-video {
    width: 100%;
    height: 90vh;
    border-bottom-left-radius: 40px;
}

.front-video video {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    height: 70%;
    width: 100%;
    object-fit: cover;
    margin-top: 12vh;
}

.icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 20px;
    overflow: hidden;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 30px;
    width: 80%;
}

.icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    z-index: 100;
}

.icon-container img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
}

.icon-container p {
    text-align: center;
    font-size: 14px;
    color: black;
}

.felszereltseg-button {
    background-color: rgb(245, 241, 241);
    color: black;    
    border: none;
    padding: 20px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 1.2em;
    border: #2d843d 1px solid;
}

.felszereltseg-button:hover {
    background-color: #20572a;
    color: white;
}

.felszereltseg-container{
    z-index: 100;
    position: absolute;
    left: 70vh;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    height: 80vh;
    overflow: scroll;
    margin: 0 auto;
    box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.3);
    font-family: 'Montserrat';
}

.features-container {
    font-family: 'Montserrat', sans-serif;
}

.features-container h2 {
    color: #8DA292;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 16px;
}

.features-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    display: flex;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #E0E0E0; /* Szín az elválasztó vonalakhoz */
    font-size: 18px;
    font-weight: 500;
}

.features-list li .icon {
    font-size: 20px;
    margin-right: 10px;
    color: #000; /* Szín az ikonhoz */
}


/* Mobil eszközök (max-width: 780px) */
@media (max-width: 780px) {
    .front-containerr {
        margin-top: 35vh;
        grid-template-columns: 1fr; /* Egy oszlopos elrendezés mobilon */ /* Kisebb padding mobilon */
        width: 100%;
        margin-bottom: 20vh;
        margin-right: 0;
        margin-left: 0;
    }
    .felszereltseg-container{
        z-index: 100;
        position: absolute;
        left: 10vh;
        background-color: white;
        padding: 20px;
        border-radius: 20px;
        height: 80vh;
        overflow: scroll;
        margin: 0 auto;
        box-shadow: -4px -4px 20px rgba(0, 0, 0, 0.3);
        font-family: 'Montserrat';
    }

    .front-szovegg {
        width: 100%; /* Teljes szélesség mobilon */
       padding-left: 0;
       padding-right: 0;
    }

    .front-szovegg h1 {
        font-size: 2em; /* Kisebb betűméret mobilon */
        text-align: center; /* Középre igazítás mobilon */
    }

    .front-szovegg p {
        font-size: 1em; /* Kisebb betűméret mobilon */
        text-align: center; /* Középre igazítás mobilon */
    }

    .p-container {
        padding: 10px; /* Kisebb padding mobilon */
        border-left: none; /* Border eltávolítása mobilon */
        text-align: center; /* Középre igazítás mobilon */
    }

    .front-video {
        height: 50vh; /* Csökkentett magasság mobilon */
        border-radius: 0px;
    }

    .front-video video {
        margin-top: 5vh; /* Kisebb felső margó mobilon */
        height: auto; /* Automatikus magasság */
        border-radius: 0px;
    }

    .icons-container {
        width: 90%; /* Teljes szélesség mobilon */
        margin: 10px 0; /* Kisebb margó mobilon */
        padding: 0px; /* Kisebb padding mobilon */
        overflow: hidden;
    }

    .icon-container {
        margin: 3px; /* Kisebb margó mobilon */
        gap: 3px;
    }

    .icon-container img {
        width: 35px; /* Kisebb ikon méret mobilon */
        height: 35px; /* Kisebb ikon méret mobilon */
    }

    .icon-container p {
        font-size: 12px; /* Kisebb betűméret mobilon */
    }

    .felszereltseg-button {
        font-size: 1em; /* Kisebb betűméret mobilon */
        padding: 8px 16px; /* Kisebb padding mobilon */
    }
}
