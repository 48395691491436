/* Alap stílusok minden eszközre */
.koszonjuk-teljes-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f8f8;
    overflow: hidden;
}

.koszonjuk-szoveg-container {
    max-width: 500px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.koszonjuk-szoveg-container h2 {
    font-size: 2em;
    margin-bottom: 20px;
    color: black;
}

.koszonjuk-szoveg-container p {
    font-size: 1.2em;
    color: #555;
}

/* Slide-in animáció */
.slide-in {
    transform: translateX(-100%);
    animation: slide-in 0.5s forwards;
}

@keyframes slide-in {
    to {
        transform: translateX(0);
    }
}

/* Mobilbarát stílusok */
@media (max-width: 768px) {
    .koszonjuk-szoveg-container {
        width: 90%;
        padding: 15px;
    }

    .koszonjuk-szoveg-container h2 {
        font-size: 1.5em;
    }

    .koszonjuk-szoveg-container p {
        font-size: 1em;
    }
}
