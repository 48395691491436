.egesz-container {
    margin-top: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .szoveg-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    width: 80%;
  }

  .szoveg-container h3{
    font-size: 2.5em;
  }

  
  .szoveg-container button {
    font-size: 1.3em;
    font-family: 'Montserrat', sans-serif;
    background-color: #2d843d;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .szoveg-container button:hover {
    background-color: #2f6d3a;
  }
  
  .apartman-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }
  
  .room-container {
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    flex: 1;
    margin: 10px;
    display: flex;
    flex-direction: column;
  }
  
  .kep-container {
    background-size: cover;
    background-position: center;
    height: 250px;
    position: relative;
  }
  
  .ar-container {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .room-details {
    padding: 20px;
    text-align: center;
  }
  
  .room-details h4 {
    margin: 10px 0;
  }
  
  .room-details p {
    color: #777;
  }
  
  .room-details a {
    color: #2d843d;
    text-decoration: none;
  }
  
  .room-details a:hover {
    text-decoration: underline;
  }
  
  .kedvezmeny-container {
    background-color: #2d843d;
    color: white;
    border-radius: 10px;
    padding: 20px;
    max-width: 300px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .kedvezmeny-container ul {
    list-style: none;
    padding: 0;
  }
  
  .kedvezmeny-container ul li {
    margin: 10px 0;
  }
  
  .kedvezmeny-container button {
    background-color: white;
    color:  #2d843d;    
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 20px;
    transition: background-color 0.5s ease;
  }
  
  .kedvezmeny-container button:hover {
    background-color: #2d843d;
    color: white;
  }
  

  @media (max-width: 768px) {
    .egesz-container {
        margin-top: 10vh; /* Csökkentett felső margó mobilon */
    }

    .szoveg-container {
        width: 100%; /* Teljes szélesség mobilon */
    }

    .szoveg-container h3 {
        font-size: 2em; /* Kisebb betűméret mobilon */
    }

    .apartman-container {
        flex-direction: column; /* Oszlopirányú elrendezés mobilon */
        align-items: center; /* Középre igazítás mobilon */
    }

    .room-container {
        width: 100%; /* Teljes szélesség mobilon */
        margin: 10px 0; /* Függőleges margó mobilon */
    }

    .kedvezmeny-container {
        width: 100%; /* Teljes szélesség mobilon */
    }
}