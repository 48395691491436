.login-group{
    width: 30vh;
   
}

.big-div{
    height: 80vh;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f0f0f0; /* háttérszín a jobb láthatóság érdekében */
}
  
  .login-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  