/* Alap stílusok minden eszközre */
.front-container {
    display: grid;
    width: 100%;
    grid-template-columns: 58% 42%;
}

.front-szoveg {
    width: 80%;
    padding-left: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 1s, transform 1s; /* Beúszó animáció balról */
}

.front-szoveg h1 {
    font-size: 3em;
    text-align: left;
}

.front-szoveg p {
    font-size: 1.2em;
    color: #2d843d;
}

.p-container {
    padding-right: 20px;
    border-left: 2.4px solid #2d843d;
    padding-left: 15px;
}

.front-kep {
    width: 100%;
    height: 90vh;
    background-position: center;
    background-size: cover;
    border-bottom-left-radius: 40px;
}


/* Nagyobb kijelzők (max-width: 1590px) */
@media (max-width: 1590px) {
    .front-container {
        grid-template-columns: 58% 42%;
    }

    .front-szoveg {
        width: 80%;
        padding-left: 10vh;
    }

    .front-szoveg h1 {
        font-size: 3em;
    }

    .front-szoveg p {
        font-size: 1.2em;
    }

    .p-container {
        padding-right: 20px;
        border-left: 2.4px solid #2d843d;
        padding-left: 15px;
    }

    .front-kep {
        height: 90vh;
    }

    .mini-foglalas-container input[type="date"],
    .mini-foglalas-container input[type="number"],
    .mini-foglalas-container input[type="submit"] {
        padding: 10px;
        font-size: 16px;
        width: 15vh;
    }
}

/* Tabletek (max-width: 1200px) */
@media (max-width: 1200px) {
    .front-container {
        background-color: #f8f8f8;
    }

    .front-szoveg {
        width: 90%;
        padding-left: 5vh;
    }

    .front-kep {
        height: 55vh;
    }

    .mini-foglalas-container input[type="date"],
    .mini-foglalas-container input[type="number"],
    .mini-foglalas-container input[type="submit"] {
        padding: 8px;
        font-size: 14px;
        width: auto;
    }
}

/* Mobil eszközök (max-width: 768px) */
@media (max-width: 768px) {
    .front-container {
        position: relative;
        grid-template-columns: 1fr;
        height: auto; /* Adjust height to auto */
        background-image: url("./fotok/dezsa.jpg");
        background-size: cover;
        background-position: center;
        overflow: hidden;
        margin-bottom: 20vh;
    }

    .front-container::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
    }

    .front-szoveg {
        padding: 0px;
        position: relative;
        z-index: 2;
        width: 100%;
        text-align: center;
        background-color: rgba(248, 248, 248, 0.8);
    }

    .front-szoveg h1 {
        font-size: 2em;
        text-align: center;
    }

    .front-szoveg p {
        font-size: 1.2em;
    }

    .p-container {
        padding: 10px;
    }

    .front-kep {
        display: none;
    }

    .mini-foglalas-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 10px;
        background-color: white;
        padding: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
        z-index: 100;
        width: 70%; /* Ensure the container fits within the screen */
    }

    .mini-foglalas-container form {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 100%;
    }

    .mini-foglalas-container input[type="date"],
    .mini-foglalas-container input[type="number"],
    .mini-foglalas-container input[type="submit"] {
        width: 100%;
        box-sizing: border-box;
        margin-bottom: 10px;
    }

    .mini-foglalas-container input[type="submit"] {
        width: 100%;
        background-color: #2d843d;
        color: white;
        cursor: pointer;
        transition: background-color 0.5s ease;
        height: 50px;
    }

    .mini-foglalas-container input[type="submit"]:hover {
        background-color: white;
        color: #2d843d;
    }
}

.mini-foglalas-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 300px; /* Szélesség beállítása */
    margin: 0 auto; /* Középre igazítás */
    box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 40px -10px;
  }
  
  .form-group {
    margin-bottom: 15px; /* Térköz az elemek között */
  }
  
  .form-label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
    font-size: 14px;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .form-submit {
    background-color: #2d843d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 12px;
    width: 100%;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .form-submit:hover {
    background-color: #205e2b;
  }

  .mini-foglalas-container input[type="date"],
  .mini-foglalas-container input[type="number"],
  .mini-foglalas-container input[type="submit"] {
      padding: 10px;
      font-size: 16px;
      width: 100%;
  }
  
  @media (max-width: 768px) {
    .mini-foglalas-container input[type="submit"] {
       height: 50px;
    }
  }
