.foglalas-container {
    
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   background: white;
}

form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
}

.form-group input:focus,
.form-group select:focus {
    border-color: #1d7728;
    outline: none;
}

button {
    padding: 10px 15px;
    background-color: #2d843d; 
    border: none;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #206b2e; 
}

button:focus {
    outline: none;
}



  .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background-color: #2d843d;
    
  }


.naptar {
    display: flex;
    justify-content: center;
    align-items: center;

}

.rdrDateDisplay {
    border-color: #206b2e;
}

.cont {
    background-color: rgb(240, 240, 240);
    width: 100%;
    height: 100%;
}
button.rdrDay{
    color: black;
    outline-color: #206b2e;
}

.rdrDayToday .rdrDayNumber span::after {
    background-color: #2d843d;
}

button.rdrDay:hover{
    color: black;
    outline-color: #206b2e;
}
  
.rdrDateDisplay{
    color: black;
}
.rdrDateDisplayWrapper{
    background-color: #ffffff;
}
.rdrDateDisplayItemActive{
    border-color: #1d7728;
}

.rdrDayHover{
    border-color: #1d7728;
}