.fogpay-teljes-container{
    width: 100%;
    height: 90vh;
    background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fogpay-szoveg-container{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   background: white;
   border-radius: 20px;
}

.fizetendo{
    font-size: 25px;
}

@keyframes slideb {
    from {
      opacity: 0;
      transform: translateX(-30%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .slide-in {
    opacity: 0;
    animation: slideIn 1s forwards;
  }
  
  .visible {
    opacity: 1;
  }
  .erk-tav{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }

  @media (max-width: 800px) {
    .fogpay-szoveg-container{
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      margin: 0 auto;
      padding: 20px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
     background: white;
     border-radius: 20px;
  }

  fogpay-teljes-container{
    width: 100%;
    height: 100vh;
    background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
}
  }


  /* foglalas_pay.css */

.payment-form {
  margin-top: 20px;
}

.payment-form h3 {
  margin-bottom: 10px;
}

.payment-form div {
  margin-bottom: 10px;
}

.payment-form label {
  margin-left: 10px;
}

.payment-form button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #2d843d;
  color: white;
  border: none;
  cursor: pointer;
}

.payment-form button:hover {
  background-color: #276b32;
}
