.room-photos-container {
    padding: 20px;
    margin: 12vh;
    margin-top: 35vh;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .header-container h1 {
    font-size: 2.5em;
  }
  
  .header-container button {
    background-color: #007BFF;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .header-container button:hover {
    background-color: #0056b3;
  }
  
  .photos-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    width: 100%;
    height: 80vh;
  }
  
  .photo-item1, .photo-item2, .photo-item3, .photo-item4 {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform 0.3s ease-in-out, background-size 0.3s ease-in-out;
}



.photo-item1:hover, .photo-item2:hover, .photo-item3:hover, .photo-item4:hover {
    transform: scale(1.02); /* Esetleges nagyítás a div elemre */
    
}

  .photo-item1 {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  .photo-item4 {
    grid-column-start: 3;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }
  
  .photo-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  

  @media (max-width: 768px) {
    .room-photos-container {
        padding: 10px;
        margin: 5vh 0;
    }

    .header-container {
        flex-direction: column;
        text-align: center;
    }

    .header-container h1 {
        font-size: 2em;
        margin-bottom: 10px;
    }

    .header-container button {
        padding: 8px 16px;
    }

    .photos-grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        gap: 10px;
        height: auto;
    }

    .photo-item1, .photo-item2, .photo-item3, .photo-item4 {
        grid-column: auto / span 1;
        grid-row: auto / span 1;
        height: 50vh;
    }
}