.f1-l{
    width: 70%;
    height: 60vh;   
}

.f1-l-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
