body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.28); /* Fekete szín 50%-os átlátszósággal */
    z-index: -1;
  }
  
  .content {
    position: relative;
    z-index: 1;
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    padding-top: 100px; /* Tartalom lefelé mozgatása */
  }
  
  .content h1 {
    font-size: 3em;
  }
  
  .content p {
    font-size: 3em;
    font-family: 'Pacifico';
    font-weight: 500;
    margin: 0;
    margin-top: -20;
    text-shadow: black 1.5px 3.5px;
  }

  .lipicslogo {
    width: 5em;
    border-radius: 10em;
  }
  /*header 
  */
  .head{
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .header-bar{
    list-style-type: none;
    color: white;
    display: flex;
    gap: 20px;
    font-size: 1.3em;
    align-items: center;
    justify-content: center;
  }
  .header-bar li {
    color: white;
    /*margin-top: -28px;*/
   
  }

  .head-bar li:hover {
   
  }

  .menu :hover {
    transition: all 0.2s ease-in-out;
    color: #2d843d;
    background-color: #ffffff;
    transition: all 0.2s ease-in-out;
  }

  .menu a {
    color: white;
    text-decoration: none;
    background-color: #2d843d;
    padding: 10px;
    border-radius: 20px;
  }
  .menu a :hover {
    border-radius: 20px;
    margin: 20px;
  }

  /*.muraratka-iras {
    font-size: 2em;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
  }

  .lipics-iras {
    font-size: 2.5em;
    font-family: "WindSong", cursive;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    text-shadow: black 1.5px 3.5px;
    margin-top: 100px;
  }

  .gallery {
    display: grid;
    gap: 20px;
    grid-auto-flow: column;
    grid-auto-rows: 30%;
    overflow-x: scroll;
    overscroll-behavior-inline: contain;
  }
  
  .gallery-item {
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    width: 300px;
    height: 400px;
    margin: 10px;
    transition: transform 0.3s ease-in-out;
  }


  .gallery-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    transition: transform 0.3s ease-in-out;
  }
  .gallery-item:hover .gallery-image {
    transform: scale(1.1);  A kép nagyítása hover esetén 
  }

  .gallery-image:hover {
    background-position: center;
    position: relative;
  }
  
  .gallery-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .gallery-content h3 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .gallery-content p {
    margin: 0;
    font-size: 1.2em;
    font-weight: bold;
  }
  
  .house-shape {
    width: 80%;
    height: 50%;
    background: rgba(255, 255, 255, 0.5);
    clip-path: polygon(50% 0%, 100% 50%, 100% 100%, 0 100%, 0 50%);
  }
  .all-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    margin: 0;
    font-family: Arial, sans-serif;
  }

  .shadow-soft {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
  }
  */