.contact-page {
  display: flex;
  flex-direction: column; /* Módosítás oszlopirányú elrendezéshez mobilon */
  justify-content: space-between;
  padding: 20px;
  background-color: #f8fbf8;
  overflow: hidden;
  margin-top: 35vh;
}

.contact-info {
  width: 100%; /* Teljes szélesség mobilon */
  margin: 0; /* Távolság eltávolítása mobilon */
}

.contact-info h1 {
  font-size: 2.5em;
  text-align: center; /* Középre igazítás mobilon */
}

.contact-info p {
  margin-bottom: 20px;
  color: black;
  text-align: center;
}

.contact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Egy oszlopos elrendezés mobilon */
  gap: 20px; /* Nagyobb távolság a mobil jobb olvashatóságához */
  
}

.contact-item {
  display: flex;
  align-items: center;
  background-color: #f8fbf8;
  padding: 10px; /* Nagyobb padding a jobb érintési területhez */
  border-radius: 10px;
  justify-content: baseline;
  margin-left: 25%;
}

.icon {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: center;
}

.phone-icon {
  background-image: url('./fotok/iconok/phone-call.png');
}

.email-icon {
  background-image: url('./fotok/iconok/envelope.png');
}

.location-icon {
  background-image: url('./fotok/iconok/land-layer-location.png');
}

.time-icon {
  background-image: url('./fotok/iconok/clock-three.png');
}

.icon-containerr {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 50px;
  height: 50px;
  border: 0.6px solid black;
  border-radius: 10px;
  margin-right: 15px;
}

.map{
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .contact-page {
      flex-direction: column; /* Oszlopirányú elrendezés mobilon */
      padding: 10px; /* Kisebb padding mobilon */
  }

  .contact-info {
      width: 100%; /* Teljes szélesség mobilon */
      margin: 0; /* Margin eltávolítása mobilon */
      padding: 0 10px; /* Kis padding a jobb megjelenéshez */
  }

  .contact-info h1 {
      font-size: 2em; /* Kisebb betűméret mobilon */
      text-align: center; /* Középre igazítás mobilon */
  }

  .contact-info p {
      font-size: 1em; /* Kisebb betűméret mobilon */
      text-align: center; /* Középre igazítás mobilon */
  }

  .contact-grid {
      grid-template-columns: 1fr; /* Egy oszlopos elrendezés mobilon */
      gap: 10px; /* Kisebb gap mobilon */
  }

  .contact-item {
      flex-direction: column; /* Oszlopirányú elrendezés a contact itemeknél mobilon */
      padding: 15px; /* Nagyobb padding mobilon */
      text-align: center; /* Középre igazítás a contact itemeknél mobilon */
      justify-content: center;
      margin-left: 0;
      align-items: center;
  }

  .icon-containerr {
      margin-bottom: 10px; /* Kis margin az ikonok alatt mobilon */
  }

  .map {
      width: 100%; /* Teljes szélesség mobilon */
      height: 100%; /* Kisebb magasság mobilon */
      padding: 0px; /* Kisebb padding mobilon */
      display: flex;
      justify-content: center;
      align-items: center;
  }
}
