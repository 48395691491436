.ratings-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Automatikusan több sorba helyezi az elemeket, ha szükséges */
  padding: 20px;
  background-color: white;
  margin: 15vh;
  border-radius: 30px;
}

.rating {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 10px;
  flex-basis: calc(33.333% - 20px); /* Három elem egy sorban */
}

.score {
  font-size: 24px;
  font-weight: bold;
}

.score span {
  font-size: 16px;
  color: #777;
}

.comments {
  font-size: 14px;
  color: #777;
  margin: 5px 0;
}

.logo img {
  width: 120px;
  height: auto;
}

/* Mobil eszközök (max-width: 768px) */
@media (max-width: 768px) {
  .ratings-container {
      margin: 5vh 0; /* Csökkentett felső és alsó margó mobilon */
      padding: 10px; /* Csökkentett padding mobilon */
      margin-bottom: 20vh;
  }

  .rating {
 /* Egy elem egy sorban */
      margin: 10px 0; /* Függőleges margó a jobb elrendezés érdekében */
      
  }

  .score {
      font-size: 20px; /* Kisebb betűméret mobilon */
  }

  .score span {
      font-size: 14px; /* Kisebb betűméret mobilon */
  }

  .comments {
      font-size: 12px; /* Kisebb betűméret mobilon */
  }

  .logo img {
      width: 80px; /* Kisebb logó méret mobilon */
  }
}
