.foglalasok-container {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: center;
}

.vendegkezelo-item {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 6px;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 100%;
  overflow: scroll;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.vendegkezelo-item:hover {
  transform: scale(1.02);
}

.vendegkezelo-item h4 {
  margin: 8px 0;
  color: #333;
}

.vendegkezelo-item input {
  width: calc(100% - 10px);
  padding: 5px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.icon {
  width: 10px;
  height: 10px;
  cursor: pointer;
  margin: 5px;
}

/*.editicon {
  filter: invert(35%) sepia(77%) saturate(637%) hue-rotate(87deg) brightness(93%) contrast(92%);
}

.returnicon {
  filter: invert(35%) sepia(77%) saturate(637%) hue-rotate(87deg) brightness(93%) contrast(92%);
}

.deleteicon {
  filter: invert(35%) sepia(77%) saturate(637%) hue-rotate(87deg) brightness(93%) contrast(92%);
}*/
.vendegkezelo-item {
  border: 1px solid #ddd;
  padding: 16px;
  margin: 16px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.field {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.field-title {
  font-weight: bold;
  margin-right: 8px;
  flex: 1;
}

.field-value {
  flex: 2;
  text-align: right;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-top: 16px;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0 8px;
  cursor: pointer;
}

.save-button {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 8px;
}

.save-button:hover {
  background-color: #45a049;
}

input[type="text"],
input[type="phone"],
input[type="date"] {
  width: 100%;
  padding: 8px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="text"]:focus,
input[type="phone"]:focus,
input[type="date"]:focus {
  border-color: #4caf50;
  outline: none;
}

@media (max-width: 768px) {
  .field {
    flex-direction: column;
    align-items: flex-start;
  }

  .field-value {
    text-align: left;
    margin-top: 4px;
  }

  .icon-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    margin: 8px 0;
  }

  .save-button {
    margin-left: 0;
    margin-top: 8px;
  }
}




.vendegkezelo-item {
  border: 1px solid #ddd;
  padding: 12px;
  margin: 12px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.field {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
}

.field-title {
  font-weight: bold;
  margin-right: 8px;
  flex: 1;
}

.field-value {
  flex: 2;
  text-align: right;
}

.icon-container {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.icon {
  width: 24px;
  height: 24px;
  margin: 0 6px;
  cursor: pointer;
}

.save-button {
  padding: 6px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 6px;
}

.save-button:hover {
  background-color: #45a049;
}

input[type="text"],
input[type="phone"],
input[type="date"] {
  width: 100%;
  padding: 6px;
  margin: 6px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="text"]:focus,
input[type="phone"]:focus,
input[type="date"]:focus {
  border-color: #4caf50;
  outline: none;
}

@media (max-width: 768px) {
  .field {
    flex-direction: column;
    align-items: flex-start;
  }

  .field-value {
    text-align: left;
    margin-top: 4px;
  }

  .icon-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .icon {
    margin: 6px 0;
  }

  .save-button {
    margin-left: 0;
    margin-top: 6px;
  }
}

.rbc-event-content{
  background-color: #328036;
}

.rbc-event{
  background-color: #328036;
  border: black;
}